
import { Vue, Prop, Component } from "vue-property-decorator";
import PdfMakeComponent from "./pdf-make.vue";
import PayrollPdfMakeComponent from "./payroll-pdf.vue";
import StatementsOfAccountPdfMakeComponent from "./pdf-statements-of-accounts.vue"
import SalaryPdfMakeComponent from "./salary-pdf.vue";
@Component({
    components: {
        PdfMakeComponent,
        PayrollPdfMakeComponent,
        StatementsOfAccountPdfMakeComponent,
        SalaryPdfMakeComponent
    },
})
export default class ExportOptions extends Vue {
    @Prop({ type: Array, default: () => [] }) rowData!: any[];
    @Prop({ type: Array, default: () => [] }) columnsData!: any[];
    @Prop({ type: Array, default: () => [] }) widths!: any[];
    @Prop({ default: null }) AccountFrom!: string;
    @Prop({ default: null }) AccountTo!: string;
    @Prop({ default: null }) PeriodFrom!: string;
    @Prop({ default: null }) PeriodTo!: string;
    @Prop({ default: 'must give file name' }) fileName!: string;
    @Prop({ default: null }) sheetName!: string;
    @Prop({ type: Object, default: () => { } }) fieldsMapping!: any;
    @Prop({ type: Array, default: () => [] }) visibleFields!: any[];

}

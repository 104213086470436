
import { Vue, Prop, Component } from "vue-property-decorator";
import ExportOptions from "./export-options.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
import accountService from "@/services/account-service";

@Component({
  components: {
    ExportOptions,
    VueSlider,
    DatePicker: VueDatepickerUi,
  },
})
export default class FilterComponent extends Vue {
  @Prop({ type: Array, default: () => [] }) filter_fields!: any[];
  @Prop({ type: Array, default: () => [] }) advance_filter_fields!: any[];
  @Prop({ type: Function, default: () => { } }) handleFilterChange!: Function;
  @Prop({ type: Function, default: () => { } }) handleonClick!: Function;
  // @Prop({ type: Function, default: () => {} }) resetDates!: Function;
  @Prop({ type: Function, default: () => { } }) refreshFields!: Function;
  @Prop({ type: Function, default: () => { } }) refreshAdvanceFields!: Function;
  @Prop({ type: Function, default: () => { } }) refreshDateFeild!: Function;
  @Prop({ type: Array, default: () => [] }) rowData!: any[];
  @Prop({ type: Array, default: () => [] }) columnsData!: any[];
  @Prop({ type: Array, default: () => [] }) widths!: any[];
  @Prop({ default: "must give file name" }) fileName!: string;
  @Prop({ default: null }) sheetName!: string;
  @Prop({ type: Array, default: () => [] }) options_account_from!: any[];
  @Prop({ type: Array, default: () => [] }) options_account_to!: any[];
  @Prop({ type: Array, default: () => [] }) options_account!: any[];
  @Prop({ type: Object, default: () => { } }) account_selected_from!: any;
  @Prop({ type: Object, default: () => { } }) account_selected_to!: any;
  @Prop({ type: Object, default: () => { } }) account_selected!: any;
  @Prop({ type: Function, default: () => { } }) search_parent_account!: Function;
  @Prop({ type: Function, default: () => { } }) get_parent_account!: Function;
  @Prop({ type: Object, default: () => { } }) fieldsMapping!: any;
  @Prop({ type: Array, default: () => [] }) visibleFields!: any[];

  get fields(): any[] {
    return this.filter_fields;
  }
  set fields(value: any[]) {
    this.$emit("update:filter_fields", value);
  }
  submitFields() {
    const updatedFields = this.filter_fields.filter(
      (filter) => filter.value !== ""
    );
    updatedFields.forEach((filter) => this.handleFilterChange(filter));
  }
  submitAdvanceFeilds() {
    const updatedAdvanceFields = this.advance_filter_fields.filter(
      (filter) => filter.value !== ""
    );
    updatedAdvanceFields.forEach((filter) => this.handleFilterChange(filter));
  }
}
